@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
.loading{
    background-color: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.label1{
  position: relative;
  height: 125px;
  width: 125px;
  display: inline-block;
  border: 2px solid rgba(255,255,255,0.2);
  border-radius: 50%;
  border-left-color: #5cb85c;
  animation: rotate 1.2s linear infinite;
}
@keyframes rotate {
  50%{
    border-left-color: #9b59b6;
  }
  75%{
    border-left-color: #e67e22;
  }
  100%{
    transform: rotate(360deg);
  }
}
.label1 .check-icon{
  display: none;
}
.label1 .check-icon:after{
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  transform: scaleX(-1) rotate(135deg);
  height: 56px;
  width: 28px;
  border-top: 4px solid #5cb85c;
  border-right: 4px solid #5cb85c;
  transform-origin: left top;
  animation: check-icon 0.8s ease;
}
@keyframes check-icon {
  0%{
    height: 0;
    width: 0;
    opacity: 1;
  }
  20%{
    height: 0;
    width: 28px;
    opacity: 1;
  }
  40%{
    height: 56px;
    width: 28px;
    opacity: 1;
  }
  100%{
    height: 56px;
    width: 28px;
    opacity: 1;
  }
}
input#check{
    display: none;
}
input:checked ~ .label1 .check-icon{
  display: block;
}
input:checked ~ .label1{
  animation: none;
  border-color: #5cb85c;
  transition: border 0.5s ease-out;
}

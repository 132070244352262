.orderDetailsPage {
    background-color: white;
}

.orderDetailsContainer>h1 {
    font: 300 3vmax "Roboto";
    margin: 4vmax 0;
    color: #3BB77E;
}

.orderDetailsContainer {
    padding: 5vmax;
    padding-bottom: 0%;
}

.orderDetailsContainer>p {
    font: 400 1.8vmax "Roboto";
}

.orderDetailsContainerBox,
.orderDetailsCartItemsContainer {
    margin: 2vmax;
}

.orderDetailsContainerBox>div {
    display: flex;
    margin: 1vmax 0;
}

.orderDetailsContainerBox>div>p {
    font: 400 1vmax "Roboto";
    color: black;
}

.orderDetailsContainerBox>div>span {
    margin: 0 1vmax;
    font: 100 1vmax "Roboto";
    color: #575757;
}

.orderDetailsCartItems>p {
    font: 400 1.8vmax "Roboto";
}

.orderDetailsCartItems {
    padding: 2vmax 5vmax;
    border-top: 1px solid rgba(0, 0, 0, 0.164);
}

.orderDetailsCartItemsContainer>div {
    display: flex;
    font: 400 1vmax "Roboto";
    align-items: center;
    margin: 2vmax 0;
}

.orderDetailsCartItemsContainer>div>img {
    width: 3vmax;
}

.orderDetailsCartItemsContainer>div>a {
    color: #575757;
    margin: 0 2vmax;
    width: 60%;
    text-decoration: none;
}

.orderDetailsCartItemsContainer>div>span {
    font: 100 1vmax "Roboto";
    color: #5e5e5e;
}

@media screen and (max-width: 600px) {
    .orderDetailsContainer>p {
        font: 400 6vw "Roboto";
    }

    .orderDetailsContainerBox>div {
        margin: 6vw 0;
    }

    .orderDetailsContainerBox>div>p {
        font: 400 4vw "Roboto";
    }

    .orderDetailsContainerBox>div>span {
        font: 100 4vw "Roboto";
    }

    .orderDetailsCartItems>p {
        font: 400 6vw "Roboto";
    }

    .orderDetailsCartItemsContainer>div {
        font: 400 4vw "Roboto";
        margin: 4vw 0;
    }

    .orderDetailsCartItemsContainer>div>img {
        width: 10vw;
    }

    .orderDetailsCartItemsContainer>div>a {
        margin: 2vw;
        width: 30%;
    }

    .orderDetailsCartItemsContainer>div>span {
        font: 100 4vw "Roboto";
    }
}
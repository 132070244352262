.bottomOption {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 60px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 2px 5px rgb(0 0 0 / 65%);
    background-color: #fff;
    left: 0;
}

.icon {
    display: flex;
}
ul.pagination{
    display: flex;
    align-items: center;
    padding: 0;
}
.page-item{
    background-color: #fff;
    list-style: none;
    border: 1px solid rgba(0,0,0,0.178);
    padding: 1vmax 1.5vmax;
    transition: all 0.3s;
    cursor: pointer;
}
.page-item:first-child{
    border-radius: 5px 0 0 5px;
} 
.page-item:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

} 
.page-link{
    font: 300 0.7vmax "Roboto";
    color: rgb(80,80,80);
    transition: all 0.3s;
}
.page-item:hover{
    background-color: rgb(230,230,230);
}
.page-item:hover .page-link{
    color: rgb(37, 37, 37);
}
.pageItemActive{
    background-color: #197EF3;
}
.pageLinkActive{
    color: white;
}
li.category-link{
    list-style: none;
    font-family: Roboto;
    cursor: pointer;
    border-top: 1px solid #999;
    padding: 10px 10px;
}

.bg{
    background-color: #252839;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg span{
    font-size: 10vh;
    color: #252839;
    position: relative;
    letter-spacing: 5px;
    -webkit-text-stroke: 0.3vw #383d52;
    text-transform: uppercase;
    padding: 0px 10px;
}
.bg span::before{
    content: attr(dataText);
    position: absolute;
    top: 0;
    letter-spacing: 5px;
    left: 0;
    width: 0%;
    height: 100%;
    color: #01fe87;
    -webkit-text-stroke: 0vw #383d52;
    padding: 0px 10px;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate {
    0%,10%,100%{
        width: 0%;
    }
    70%,90%{
        width: 100%;
    }
}
.circle{
    width:35px;
    height: 35px;
    border-radius: 50%;
    background-color: yellow;
    position: fixed;
    top: 10%;
    right: 10%;
    border: none;
    animation: zoom 3s linear infinite;
}
@keyframes zoom {
    0%,100%{
        transform: scale(1.5);
    }
    50%{
        transform: scale(.7);
    }
}

